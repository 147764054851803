import React from "react"
import { PageTitle, SpanDivider } from "../components/UtilComponents"
// @ts-ignore
import Fade from "react-reveal/Fade"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { graphql } from "gatsby"
// @ts-ignore
import { getSectionsData} from "../util/helpers"

// @ts-ignore
function BlogList({data,pageContext, location:{pathname:path}}) {
  const secData = getSectionsData(data.template.sections)
  // @ts-ignore
  return (
    <Layout lang={pageContext.lang} page={path} empty>
      <SEO title="Home" />
    <div className="content content--blog content--inner">
      <Fade top>
        <PageTitle>
          <SpanDivider fontSize="calc(30px + (40 - 30) * ((100vw - 300px) / (900 - 300)))"> | </SpanDivider> Blog
        </PageTitle>
        <span style={{textAlign: "center", fontSize:"18px", fontWeight: "bold", display:"block"}} className="content--blog__subtitle">{secData.head.description[pageContext.lang]}</span>
      </Fade>
    </div>
    </Layout>
  )
}
export const query = graphql`
  {
  template(name: {eq: "blog_list"}) {
    sections {
        items {
          ... on DataItemTextGet {
            __typename
            locales {
              iso
              value
            }
          }
          key
        }
        keyPrefix
      }
  }
}`

export default BlogList
